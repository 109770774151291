<template>
  <transition name="modal">
    <div class="modal-mask" @click="hide()">
      <div class="modal-container" v-if="getModalInfo"
           @click.stop>
        <div class="close-div">
          <img @click="hide()"
               src="https://gcdn.bionluk.com/site/icon/tansel_modal_close_ince.svg">
        </div>

        <div class="freelancers-modal-body">

          <div class="freelancers-modal-review-main">
            <img class="review-modal-image" :src="card.modalInfo.modalImageUrl"/>
            <div class="freelancer-modal-info">
              <p class="freelancer-modal-text">{{card.text}}</p>
              <div style="display: flex; margin-top: 15px; height: 16px;">
                <p class="freelancer-modal-username">{{card.username}}</p>
                <p class="freelancer-modal-title">{{card.title}}</p>
              </div>
            </div>
          </div>

          <div class="freelancers-modal-info-main">
            <div class="info-title-div">
              <p class="freelancers-modal-info-title">{{card.modalInfo.title}}</p>
            </div>
            <div class="first-info-cell">
              <div class="info-a" v-for="info in card.modalInfo.info">
                <img class="info-a-image" :src="info.infoImage"/>
                <p class="info-a-title">{{info.title}}</p>
                <p class="info-a-data">{{info.data}}</p>
              </div>
            </div>
            <div class="freelancers-modal-info-bottom">
              <p class="info-bottom-title">Aramıza katılmak ister misin?</p>
              <div class="info-bottom-button">
                <router-link style="color: white" class="nounderlinehover" to="/become_a_freelancer">
                  Hemen Başla
                </router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>

  export default {
    name: "src-pages-modals-forfreelancers-v1",
    data() {
      return {
        card: null
      }
    },
    methods: {
      hide() {
        this.$store.commit(this.types.DISABLE_MODAL);
      },
    },

    computed: {
      getModalInfo() {
        if (!this.card) {
          let data = this.$store.state.activeModal.info;
          this.card = data;
        }
        return true;
      }
    },
    created() {
    }
  }

</script>

<style scoped lang="scss">

  .modal-mask {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
    background-color: rgba(0, 0, 0, .8);
  }

  .portfolio-modal-close-button {
    position: absolute;
    top: 20px;
    right: 160px;
    z-index: 999799 !important;
    cursor: pointer;
    width: 24px;
    height: 24px;
  }

  .modal-container {
    font-family: 'sofia-pro';
    margin: auto;
    padding: 0;
    width: calc(100vw - 20px) !important;
    height: calc(100vh - 20px);
    max-width: 1140px;
    max-height: 673px;
    background-color: #ffffff;
    box-shadow: 0 20px 30px 0 rgba(0, 0, 0, 0.15);

    overflow-y: scroll;
    position: relative;
  }

  .close-div {
    position: absolute;
    top: 16px;
    right: 16px;

    img {
      cursor: pointer;
      width: 16px;
      height: 16px;
    }
  }

  .freelancers-modal-body {
    display: flex;
    flex-direction: row;
    overflow: auto;
    width: 100%;
    height: 100%;
  }

  /*sol taraf*/
  .freelancers-modal-review-main {
    width: 694px;
    height: 500px;
    .review-modal-image {
      width: 694px;
      height: 500px;
    }
  }

  .freelancer-modal-info {
    padding: 26px 26px 26px 20px;
    width: 648px;
    height: 118px;
    background-color: #000000;
    .freelancer-modal-text {
      font-size: 20px;
      font-style: italic;
      line-height: 1.4;
      color: #ffffff;
      height: 84px;
      overflow-y: auto;
    }

    .freelancer-modal-username {
      font-size: 16px;
      font-weight: 500;
      color: #ffffff;
    }

    .freelancer-modal-title {
      font-size: 12px;
      color: #ffffff;
      margin-left: 10px;
      align-self: flex-end;
    }
  }

  /*sağ taraf*/


  .freelancers-modal-info-main {
    width: 446px;
    .info-title-div {
      padding-top: 24px;
      padding-bottom: 21px;
      border-bottom: solid 2px rgba(151,151,151,0.2);
    }
    .freelancers-modal-info-title {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
      color: #333333;

    }
  }

  .first-info-cell {
    width: 446px;
    height: 423px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    .info-a {
      width: 222px;
      height: 211px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom: solid 2px rgba(151,151,151,0.2);
      &:nth-child(2n) {
        border-left: solid 2px rgba(151,151,151,0.2);
      }
      .info-a-image {
        width: 42px;
        height: 40px;
      }

      .info-a-title {
        opacity: 0.7;
        font-size: 18px;
        font-weight: 300;
        text-align: left;
        color: #333333;
        margin-top: 33px;
      }

      .info-a-data {
        font-size: 28px;
        font-weight: 600;
        text-align: center;
        color: #333333;
        margin-top: 12px;
      }
    }
  }

  .freelancers-modal-info-bottom {
    width: 446px;
    height: 173px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .info-bottom-title {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.4;
    text-align: center;
    color: #333333;

  }

  .info-bottom-button {
    margin-top: 11px;
    cursor: pointer;
    width: 240px;
    height: 50px;
    border-radius: 4px;
    background-color: #fd4056;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    line-height: 50px;
  }

  .info-bottom-button:hover {
    margin-top: 11px;
    cursor: pointer;
    width: 240px;
    height: 50px;
    border-radius: 4px;
    background-color: #FF2D55;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #ffffff!important;
    line-height: 50px;
    text-underline: none;
  }

</style>
